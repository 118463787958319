$background-light-color: #2F4860;
$background-dark-color: #202933;
$background-flash-color: #0275d8;
$link-color:#5eb3fd;
$selected-item-indicator: #ffe51b; /*#ED006C;*/
$invalid-item-color: #FF0000;
$disabled-item-color: #b2b2b2;
$text-color: #FFFFFF;

body {
	background-color: #17171C; 
	color:#FFFFFF;  
}

a {
  color:$link-color;
}

.Select {
  min-width: 130px;
}

.epLeftMenuItem {
	color:white;
	padding:8px;
	border-left: transparent 3px solid; 
	&:hover {
		background-color:rgba(240,240,240,0.3);
		font-weight: 800;
	}
}

.epLeftMenuSelectedItem {
	background-color:rgba(200,200,200,0.2);
	border-left: $selected-item-indicator 3px solid;
}

.epLeftMenuAddItem { 
	width:150px;
	margin-left:12px; 
}

.epLeftMenuWrap {
	width:250px;
	float:left;
	position: relative;
	z-index:9;
} 

.epLeftMenuBack {
	background-color:#1D242D;
	width:250px;
	height:100%;
	float:left;
	position: fixed;
    top: 40px;
    bottom: -120px;
    z-index: 1;
} 

.epLeftMenuCategory {
	color: white;
	padding:4px 6px 4px 6px;
	border-left: transparent 3px solid;
	&:hover {
		background-color:rgba(240,240,240,0.3);
		font-weight: 800;
	}
} 

.epLeftMenuCategory.isActive {
	background-color:rgba(200,200,200,0.2);
	border-left: $selected-item-indicator 3px solid;
}

.epContent {
	margin-left:280px;
	margin-right:30px;
	overflow-x: hidden;
}

.epDefaultPanel {
  background-color: $background-dark-color;
  border-radius: 5px; 
  overflow: hidden;
  min-width: 160px;
}

.epTitleBar {
  background-color:$background-light-color;
  width:100%;
  color:#FFFFFF;
  font-size: 1.1em;
  padding:6px 5px 6px 10px;
  display: flex;
  line-height: normal;
  height:40px;
}

.epTitleBarTextWrapper {
  overflow: hidden;
}

.epTitleBarButtons {
  position: absolute;
  right: 6px;
}

.epTitleBarButtons {
  list-style: none;
}

.epPanelContent {
  margin:10px;
}

.editorPanel {
	background-color: $background-dark-color;
	width:100%; 
	border-radius: 5px; 
	overflow: hidden;
}



.editorPanelFunction {
	width:320px; 
	height:220px;
	margin-bottom: 16px;
	margin-right: 16px;
	display: inline-table !important;
	position: relative;
	overflow: visible !important;
}

.functionTypeDescription {
	margin:6px 0px 6px 16px;
}

.editorPanelButtons {
	text-align: right;
    padding-top: 8px;
}

.editorPanelContent {
	margin:10px;
}

.editorPanelDescription {
  margin:10px;
  min-height:80px;
}
.editorArgPanel {
	min-height:220px;
}

.editorArgPanelTable {
  width: 100%;
}
.editorArgPanelTable td:first-child {
  width: 102px;
}

.editorPanel h2 {
	background-color:$background-light-color;
	width:100%;
	color:#FFFFFF;
	font-size: 1.1em;
	padding:6px 5px 6px 10px;
}

.editorPanel ul{
	padding-left: 0px;
}
.editorPanel ul li {
	list-style: none;
  	padding-left: 14px;
  	padding-right: 6px;
  	display: inline-block;
  	width: 100%;
  	border-left: transparent 3px solid;
}

.editorPanel ul li:hover {
	background-color: rgba(116,116,116,0.4);
	/*border-left: #0A78EF 3px solid;*/
}
.editorPanel ul li a:hover, .editorPanel ul li a:focus {
	color:white;
	text-decoration: none;
}

.editorPanel .selectedItem, .editorPanel .selectedItem:hover {
	background-color: rgba(116,116,116,0.3);
	border-left: $selected-item-indicator 3px solid;
}

.epUploadPanel {
  left:auto;
  right:24px;
  bottom: 24px;
  position: fixed;
  z-index:2900;
  align-items: center;
  border: 1px solid rgb(47, 72, 96);
}

.epPlayerMediaPanel, {
  position: fixed;
  left:auto;
  right:24px;
  top:100px;
  z-index: 3000;
  align-items: center;
  border: 1px solid rgb(47, 72, 96);
}

.epLogPanel {
  position: fixed;
  left:auto;
  right:24px;
  top:200px;
  z-index: 3000;
  align-items: center;
  border: 1px solid rgb(47, 72, 96);
}

.gameStatusControllerMainButtons {
  margin-bottom: 15px;
}

.questionItem {
	width:280px;
	display:inline;
}

.floatRight {
	float:right;
}

.floatLeft {
	float:left;
}

.btn-small {
  padding:2px 6px 2px 6px;
}

.btn {
	background-color: $background-light-color;
	border:black;
}

.btn:hover {
	background-color: $background-flash-color;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
	color:$text-color;
}

.argLink {
  text-decoration: underline;
}

.argLinkActive, .argLinkActive:hover, 
.argLinkActive:active, .argLinkActive:focus,
.argLink:hover, .argLink:active {
  color:$selected-item-indicator;
}

.argLinkInvalid, .argLinkInvalid:hover, .argLinkInvalid:active {
  color:$invalid-item-color;
}

.argLinkDisabled, .argLinkDisabled:hover, .argLinkDisabled:active {
  color:$disabled-item-color;
  pointer-events: none;
}



