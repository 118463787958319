[type="button"] {
  margin-right:2px;
  margin-bottom:2px;
}

#padContainer {
  background-color: $background-dark-color;
  border-radius: 5px; 
  overflow: hidden;
  min-width: 160px;
  padding-top: 14px;
  padding-bottom: 34px;
  position: relative;
}

.padCategory {
  margin-top: 8px;
  margin-bottom: 8px;
}
.log-area {
  height: 200px;
  width:100%;
  overflow-y: scroll;
  padding-top:0px;
}

.messages{
  padding-left: 15px;
}

.connection-status {
  height: 50px;
  width:50px;
  border-radius: 25px;
  position: absolute;
  right: 10%;
  top:10%;
}
.disconnected {
  background-color:red;
}
.connected {
  background-color:green;
}

.count-down {
  font-size: 50px;
}
.count-down-explanation {
  font-size: 14px;
}

/* Popup */
.popup-content {
    visibility: hidden;
    width: 160px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    margin-left: -80px;
}

/* Popup arrow */
.popup-content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup-content-show {
    visibility: visible !important;
    -webkit-animation: fadeIn 0.2s;
    animation: fadeIn 0.2s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {opacity: 0;} 
    to {opacity: 1;}
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
}

.btn-active, .btn-active:focus, .btn-active:active{
      color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c;
    display: inline-block;
    font-weight: normal;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    font-size: 1rem;
    border-radius: 0.25rem;
}

.stateTable td:first-child {
  padding-right:12px;
}