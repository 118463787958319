@font-face {
  font-family: "Electrolize";
  src: url("../fonts/Electrolize-Regular.eot"); /* IE9 Compat Modes */
  src: url("../fonts/Electrolize-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/Electrolize-Regular.otf") format("opentype"), /* Open Type Font */
    url("../fonts/Electrolize-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/Electrolize-Regular.woff") format("woff"), /* Modern Browsers */
    url("../fonts/Electrolize-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Titillium Web";
  src: url("../fonts/TitilliumWeb/TitilliumWeb-Regular.woff2") format("woff2");
  src: url("../fonts/TitilliumWeb/TitilliumWeb-Regular.eot"),
    url("../fonts/TitilliumWeb/TitilliumWeb-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/TitilliumWeb/TitilliumWeb-Regular.otf") format("opentype"), /* Open Type Font */
    url("../fonts/TitilliumWeb/TitilliumWeb-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/TitilliumWeb/TitilliumWeb-Regular.woff") format("woff"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}