
@import './clockCrypto';

#bundle-clock {
  height: 100%;
}

.clock-default-hint {
  position: absolute;
  bottom: 10%;
  left: 80px;
  margin: auto;
  font-size: 4em;
  color: white;
}

.clock-default-hint {
  font: normal 5em "Helvetica Neue", Helvetica, sans-serif;
  height:20%;
  width:100%;
  left:0;
  text-align: center;
}

.clock-default-hintText {
  max-width: 80%;
  height: 100%;
  text-align: center;
  margin:auto;
}