/* body */
body {
    background: white;
    background-repeat: no-repeat;
    font-family: $lato;
    background-size: cover; 
}

// outer-container for background image
#outer-container {
    background-attachment: fixed;
    margin:0;
}

#app {
    animation: fadein ease-out 1.4s;
}

.appNavigation {
  z-index:999;
  position: absolute;
  top: 0px;
}

#editorContainer {
  margin-top: 40px;
}

button:focus {
  /* outline: 1px dotted; */
  outline: none !important;
  }

a {
  text-decoration: none;
}