.textField, .textFieldRoot, .textFieldInput, .textFieldFormLabel {
  color:white;
}

video {
  position: absolute;
  top:0px;
  left:0px;
  height:100%;
}

#podOptionsPanel {
  background-color: $background-dark-color;
  border-radius: 5px; 
  min-width: 160px;
  padding:20px;
  margin-top:30px;
  padding-top: 14px;
  position: relative;
  height: 100%;
  text-align: left;
  color:white;
  display: block;
}

#podOptionsPanel h1 {
  margin-bottom:24px;
}

.podop h1, .panelHeader {
  background-color:$background-light-color;
  width:100%;
  color:#FFFFFF;
  font-size: 2em;
  padding:6px 5px 6px 10px;
}

.MuiFormControl-marginNormal-5 {
  margin-top: 0px !important;
}

.pod-section {
  margin-bottom: 20px;
}

a:hover, a:focus {
  color:white;
  text-decoration: none;
}

.inputLabel {
  margin-left: 10px;
  display: inline;
}