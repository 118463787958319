/* variables */

// colours
$lightGrey: #fafafa;
$midGrey: #ccc;
$darkGrey: #343434;
$limeGreen: #00FC87;

// typography
$lato: 'Lato', sans-serif;
$oswald: 'Oswald', sans-serif;
$Ionicons:'Ionicons';

// padding
.nopadding {
  padding: 0;
}
